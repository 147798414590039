<template>
  <a-modal
    v-model="isVisible"
    class="search_member-popup"
    title="Search & Invite Members"
    @cancel="close"
  >
    <a-row type="flex" align="middle" :gutter="1" style="margin-bottom: 25px">
      <a-col :xs="22">
        <a-select
          v-model="club_id"
          v-decorator="[
            'club_id',
            {
              rules: [{ required: true, message: 'Please select a club.' }],
            },
          ]"
          placeholder="Select a club"
          style="width: 109%"
        >
          <a-select-option
            v-for="(club, index) in clubList"
            :key="index"
            :value="club.id"
          >
            {{ club.club_name }}
          </a-select-option>
        </a-select>
      </a-col>
    </a-row>
    <a-row type="flex" justify="space-between" :gutter="1">
      <a-col :xs="24" :sm="18" :md="17">
        <a-input
          v-model="keyword"
          type="email"
          placeholder="Enter member's email"
          style="width: 100%; margin-bottom: 15px"
        ></a-input>
      </a-col>
      <a-col :xs="24" :sm="6" :md="5">
        <a-button
          block
          type="primary"
          :disabled="!club_id"
          @click="searchEmails"
        >
          Search
        </a-button>
      </a-col>
    </a-row>

    <div class="modal_searchdata_container">
      <a-row
        v-for="member in members"
        :key="member.user_id"
        type="flex"
        :gutter="4"
        class="modal_searchdata"
      >
        <a-col :span="24" :sm="12" :md="8">
          {{ member.first_name }} {{ member.last_name }}..
        </a-col>
        <a-col :span="24" :sm="12" :md="8">
          {{ member.work_email }}
        </a-col>
        <a-col :span="24" :sm="12" :md="8" class="gx-text-right">
          <a-button
            v-if="!member.status || member.status === 'decline'"
            block
            size="small"
            :loading="loader && selectId === member.id"
            @click="inviteMember(member.user_id)"
          >
            Send Invite
          </a-button>
          <a-button
            v-if="
              member.status === 'invite' ||
              member.status === 'accept' ||
              member.status === 'archive'
            "
            type="danger"
            size="small"
            style="margin-bottom: 5px; margin-right: 0"
            :loading="loader && selectId === member.user_id"
          >
            <template v-if="member.status === 'invite'">
              Invitation Pending
            </template>
            <template v-if="member.status === 'archive'">Archived</template>
            <template v-if="member.status === 'accept'">
              Already a Member
            </template>
          </a-button>
          <a-button
            v-if="member.status === 'invite'"
            size="small"
            class="gx-btn-red gx-fs-sm ant-btn-block"
            type="primary"
            style="margin-bottom: 5px; margin-right: 8px"
            @click="updateClubMember(member.id, member.club_id, 'inviteModal')"
          >
            Resend Invite
          </a-button>
          <a-button
            v-if="member.status === 'invite'"
            size="small"
            class="gx-btn-red gx-fs-sm ant-btn-block"
            style="margin-bottom: 5px"
            @click="updateClubMember(member.id, member.club_id, 'cancelModal')"
          >
            Cancel Invite
          </a-button>
        </a-col>
      </a-row>
    </div>
    <div v-if="members.length < 1 && error_msg" class="gx-text-center">
      Member not found
    </div>
    <vue-instant-loading-spinner ref="Spinner"></vue-instant-loading-spinner>
    <div slot="footer"></div>
  </a-modal>
</template>

<script>
import notifications from "@/common/notifications/notification.service";
import { memberService } from "@/common/api/api.service";
import VueInstantLoadingSpinner from "vue-instant-loading-spinner/src/components/VueInstantLoadingSpinner.vue";
import { mapGetters } from "vuex";

export default {
  name: "InviteMemberModal",
  components: {
    VueInstantLoadingSpinner,
  },
  props: {
    visible: {
      default: false,
      required: true,
      type: Boolean,
    },
  },
  data() {
    return {
      form: this.$form.createForm(this),
      keyword: "",
      error_msg: false,
      members: [],
      loader: false,
      selectId: "",
      clubList: [],
      club_id: null,
    };
  },
  mounted() {
    this.fetchClubList();
  },
  computed: {
    isVisible: {
      get() {
        return this.visible;
      },
      set() {
        return false;
      },
    },
    ...mapGetters(["AUTH_USER"]),
  },
  methods: {
    fetchClubList() {
      const params = {
        role: this.AUTH_USER.select_role,
      };
      memberService.ClubList(params).then((resp) => {
        this.clubList = resp.data.result;
      });
    },
    inviteMember(memberId) {
      this.selectId = memberId;
      this.loader = true;
      const param = {
        memberId: memberId,
        role: this.AUTH_USER.select_role,
        url: window.location.origin,
        club_id: this.club_id,
      };
      memberService
        .inviteMember(param)
        .then((resp) => {
          this.loader = false;
          if (resp.data.success) {
            this.searchEmails();
            notifications.success("An invite has been sent");
          } else {
            notifications.warn(resp.data.message);
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },

    updateClubMember(memberId, club_id, status) {
      this.$refs.Spinner.show();
      setTimeout(
        function () {
          this.$refs.Spinner.hide();
        }.bind(this),
        4000
      );
      memberService
        .updateClubMember(memberId, {
          status: status,
          role: this.AUTH_USER.select_role,
          url: window.location.origin,
          clubId: club_id,
        })
        .then((resp) => {
          if (resp.data.success) {
            if (status == "cancelModal") {
              notifications.warn("Member Invitation Cancel Successfully");
              this.close();
            } else if (status == "inviteModal") {
              notifications.warn("Member Invitation Resend Successfully");
              this.close();
            }
          } else {
            notifications.warn(resp.data.message);
            this.close();
          }
        });
    },

    searchEmails() {
      this.error_msg = false;
      memberService
        .searchMembers({
          keyword: this.keyword,
          role: this.AUTH_USER.select_role,
        })
        .then((resp) => {
          if (resp.data.success) {
            this.members = resp.data.result;
            this.error_msg = true;
          } else {
            notifications.warn(resp.data.message);
          }
        });
    },
    close() {
      this.keyword = "";
      this.members = [];
      this.error_msg = false;
      this.$emit("close");
    },
  },
};
</script>
<style>
.search_member-popup .ant-modal-footer {
  padding: 10px 16px;
  text-align: right;
  border-top: 1px solid #e8e8e8;
  border-radius: 0 0 6px 6px;
  display: none;
}
.modal_searchdata {
  margin-bottom: 15px;
  border: 1px solid #d9d9d9;
  padding: 5px;
  border-radius: 5px;
  width: 100%;
  margin-left: 0px !important;
}
.modal_searchdata button.ant-btn-block.ant-btn.ant-btn-sm.ant-btn-block {
  margin: 5px 0;
}
.modal_searchdata_container {
  max-height: 40vh;
  overflow: auto;
  width: 100%;
}
</style>
